<template>
  <div class="home font-all">
    <div class="first-background-color">
      <!-- 标题部分 -->
      <div class="title">
          <img src="../assets/home-title-icon.png" alt="home-title-icon.png"/>
          <div>{{buildingName}}</div>
      </div>

      <!-- 网格信息部分 -->
      <div class="grid-info">
        <div class="grid-info-top">
          <div class="grid-info-top-detail">
            <div class="grid-info-title">{{name}}网格</div>
            <div class="grid-info-text"><span>隶属部门：</span>{{department}}</div>
            <div class="grid-info-text"><span>网格编号：</span>{{no}}</div>
            <div class="grid-info-text"><span>覆盖区域：</span>{{area}}</div>
            <div class="grid-info-text phone-text"><span>值班电话：</span><span><div><a :href="'tel:'+phone[0]" style="color: white;">{{phone[0]}}(白天)</a></div><div><a :href="'tel:'+phone[1]" style="color: white;">{{phone[1]}}(夜间)</a></div></span></div>
            <!-- <div class="grid-info-text phone-text"><span>值班电话：</span><span><div><a :href="'wtai://wp//mc;'+phone[0]" style="color: white;">{{phone[0]}}(白天)</a></div><div><a :href="'tel:'+phone[1]" style="color: white;">{{phone[1]}}(夜间)</a></div></span></div> -->
          </div>
          <div class="grid-info-top-role-img">
            <div class="grid-info-top-role">{{userRole==='admin'?"管理员":"游客"}}</div>
            <img v-bind:src="realBuildingImageSrc" alt="home-grid-card-building.png"/>
          </div>
        </div>
        <div class="grid-info-bottom">网格信息卡</div>
      </div>

      <!-- 统计信息入口组件 -->
      <van-button v-if="userRole==='admin'" class="static-info-button" type="info" v-on:click="clickToStaticInfo">统计信息入口</van-button>
      <van-button class="suggestion-info-button" type="info" v-on:click="clickToFeedback">意见反馈</van-button>
    </div>

    <!-- 网格人员组件 -->
    <div class="grid-people-top">
      <img src="../assets/home-grid-info-a-grid-icon.png" alt="home-grid-info-a-grid-icon.png"/>
      <div>网格人员</div>
    </div>
    <div class="grid-people-botttom">
      <van-grid :border="false" :column-num="4">
        <van-grid-item @click="clickToGridPerson('网格长')">
          <img src="../assets/home-grid-info-b-grid-master.png" alt="home-grid-info-b-grid-master.png">
          <div>网格长</div>
        </van-grid-item>

        <van-grid-item @click="clickToGridPerson('巡查员')">
          <img src="../assets/home-grid-info-c-xunchayuan.png" alt="home-grid-info-c-xunchayuan.png">
          <div>巡查员</div>
        </van-grid-item>

        <van-grid-item @click="clickToGridPerson('安全员')">
          <img src="../assets/home-grid-info-d-safe.png" alt="home-grid-info-d-safe.png">
          <div>安全员</div>
        </van-grid-item>

        <van-grid-item @click="clickToGridPerson('消防人员')">
          <img src="../assets/home-grid-info-e-fireman.png" alt="home-grid-info-e-fireman.png">
          <div>消防人员</div>
        </van-grid-item>

        <van-grid-item @click="clickToGridPerson('安保人员')">
          <img src="../assets/home-grid-info-f-guard.png" alt="home-grid-info-f-guard.png">
          <div>安保人员</div>
        </van-grid-item>

        <van-grid-item @click="clickToGridPerson('保洁人员')">
          <img src="../assets/home-grid-info-g-cleaner.png" alt="home-grid-info-g-cleaner.png">
          <div>保洁人员</div>
        </van-grid-item>

        <van-grid-item @click="clickToGridPerson('工程设备')">
          <img src="../assets/home-grid-info-h-jianixiuyuan.png" alt="home-grid-info-h-jianixiuyuan.png">
          <div>工程设备</div>
        </van-grid-item>

        <van-grid-item @click="clickToGridPerson('物业服务')">
          <img src="../assets/home-grid-info-i-wuye.png" alt="home-grid-info-i-wuye.png">
          <div>物业服务</div>
        </van-grid-item>

        <van-grid-item @click="clickToGridPerson('资产管理')">
          <img src="../assets/home-grid-info-j-property.png" alt="home-grid-info-j-property.png">
          <div>资产管理</div>
        </van-grid-item>

        <van-grid-item @click="clickToGridPerson('志愿者')">
          <img src="../assets/home-grid-info-k-volunteer.png" alt="home-grid-info-k-volunteer.png">
          <div>志愿者</div>
        </van-grid-item>
      </van-grid>
    </div>

    <!-- 通知组件 -->
    <div class="note-info-top">
        <img src="../assets/home-note-horn.png" alt="home-note-horn.png" />
        <div>最新通知</div>
    </div>
    <div class="note-background-color">
      <div class="note-info-bottom" id="v-for-object" v-for="(item, index) in notesShow" :key="index">
        <div class="note-info-bottom-order">{{index+1}}</div>
        <div class="note-info-bottom-text">
          <div class="note-info-bottom-text1">{{item.title}}</div>
          <div class="note-info-bottom-text234">
            <span class="note-info-bottom-text2" v-on:click="clickNote(index)">查看详情</span>
            <span class="note-info-bottom-text3">{{item.publisher}}</span>
            <van-icon name="clock-o" color="#999999"/>
            <span class="note-info-bottom-text4">{{item.created_at.slice(0,10)}}</span>
          </div>
        </div>
      </div>
      <div v-if ="notes.length>2&&notes.length>0" @click = "showNotesMore" class="note-info-more">{{moreNotesFlag?"更多>>":"收起"}}</div>
    </div>
    
    

    <!-- 意见反馈和系统简介按钮 -->
    <div class="suggestion-system-info-button">
      <van-button class="system-info-button" type="info" v-on:click="clickIntroduction">系统简介</van-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'

export default {
  name: 'home',
  data(){
      return {
        // 标题部分
        buildingName:"",
        buildingId:"",
        community:"",
        name:"",
        // 网格信息部分
        gridId: "", // 网格id
        department:'',
        no:'',
        area:'',
        phone:'',
        realBuildingImageSrc:require('../assets/home-grid-card-building.png'),
        userRole:'user',
        moreNotesFlag:true,
        notes:[],
        notesShow:[]
      }
    },
  components: {

  },

  // 组件创建之前调用钩子
  created: function () {
    this.gridId = this.$route.params.gridid
    this.fetchData();
    this.userRole = sessionStorage.getItem("role");

    // 获取网格信息
    axios.get('/api/wx/grid',{
        params: { 
          id:this.gridId
      }}).then((response) => {
        console.log("返回的网格信息是：",response.data)
        let data=response.data.data;
        // 标题
        this.buildingName=data.building_name
        // 网格信息部分
        this.department=data.department
        this.name = data.name
        this.community = data.community
        this.no=data.no
        this.area=data.area
        this.phone=data.phone.split(',')
        this.buildingId=data.building_id;
        this.gridId=data.id
        if(data.img.length!=0){
          this.realBuildingImageSrc=data.img;
        }
        // 通知
        if(typeof(data.notifications) === "undefined"||data.notifications===null){
          this.notes=[]
        }
        else{
          this.notes=data.notifications
        }
          
        this.notesShow=this.notes.slice(0,2);
      })
  },

  // 组件挂在完成钩子
  mounted:function (){
    // this.notesShow=this.notes.slice(0,2);
  },

  // 定义方法
  methods:{

    // 网络请求写在这里
    fetchData () {

      // // 请求用户信息
      // axios.get("/api/wx/user/info")
      // .then((response) => {
      //   console.log("返回的用户信息是：",response.data)
      //   let avatar = response.data.data.avatar;
      //   let nickname = response.data.data.nickname;
      //   let role = response.data.data.role;
      //   this.userRole = role
      // })
      // .catch(function (error) {
      //   console.log("错误信息：",error);
      // });

    },

    // 点击通知详情跳转逻辑
    clickNote(event){
      console.log("在home页面点击了通知详情：",this.notes[event]);
      // alert(this.notes[event].content)
      // let str = JSON.stringify(this.notes[event]);
      // //存入
      // sessionStorage.note = str;

      this.$router.push({ path: '/noticedetail/'+this.notes[event].id})
    },

    // 点击统计信息跳转
    clickToStaticInfo(event){
      console.log("在home页面点击了统计信息：",event);
      this.$router.push({ path: '/staticinfo/'+this.gridId, query: {buildingname: this.buildingName, department: this.department}})
    },

    // 点击网格人员跳转逻辑
    clickToGridPerson(event){
      console.log("在home页面点击了网格人员：",event)
      this.$router.push({ path: '/gridperson/'+event+'/'+this.gridId, query: {department: this.department,userrole:this.userRole}})
    },

    // 点击更多通知按钮逻辑
    showNotesMore(event){
      console.log('点击了“更多”按钮！')
      if(this.moreNotesFlag){
        this.notesShow=this.notes;
      }
      else{
        this.notesShow=this.notes.slice(0,2);
      }
      this.moreNotesFlag=!this.moreNotesFlag;
    },

    // 点击反馈按钮
    clickToFeedback(event){
      this.$router.push({ path: '/feedback/' + this.gridId})
    },

    // 点击系统介绍按钮
    clickIntroduction(event){
      this.$router.push({ path: '/introduction' })
    }

  }
}
</script>

<style scoped>
/* 整个组件的字体 */
.font-all {
  font-size: 14px;
  /* font-family: Microsoft JhengHei; */
  /* font-weight: bold; */
  color: #0099ff;
}

/* 第一个卡片的背景颜色 */
.first-background-color{
  background-color: #ffffff;
  padding: 10px 0;
}

/* 标题 */
.title {
  font-size: 16px;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  /* align-items: baseline; */
  justify-content: center;
  
  font-weight: bold;
  line-height: 1.14rem;
}
.title img{
  width: 38px;
  height: 30px;
}

/* 网格信息卡 */
.grid-info{
  width: auto;
  background: url("../assets/home-grid-card-background.png");
  margin: 0px 10px;
  background-size: 100% 100%;
  padding: 10px 10px 0px 20px;
  /* color: #000066; */
  color: #FFFFFF;
}
.grid-info .grid-info-top{
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.grid-info .grid-info-top img[data-v-fae5bece] {
  margin: 0.5rem 0.2667rem 0 0rem;
  width: 3.7rem;
  height: 2.66667rem;
  border-radius: 0.26667rem;
}
.grid-info .grid-info-top .grid-info-top-detail{
  flex: 1;
  margin-bottom: 8px;
}
.grid-info .grid-info-top .grid-info-title{
  font-size: 14px;
  margin: 0px 0 10px 0;
}
.grid-info .grid-info-top .grid-info-text{
  font-size: 12px;
  color: white;
  margin: 2px 0;
}
.grid-info .grid-info-top .phone-text{
  display: flex;
}

.grid-info .grid-info-top .grid-info-text span{
  font-size: 12px;
  color: #b2d9fd;
}
.grid-info .grid-info-top .grid-info-top-role-img{
  flex-direction: column;
}
.grid-info .grid-info-top .grid-info-top-role{
  font-size: 14px;
  text-align: right;
  margin: 0px 30px 10px 0px;
}
.grid-info .grid-info-top img{
  margin: 0 10px 0 0;
  width: 120px;
  height: 100px;
  border-radius: 10px;

}/*图片太高，需要重新弄一下*/
.grid-info .grid-info-bottom{
  position: relative;
  bottom: 8px;
}

/* 统计信息入口按钮 */
.static-info-button{
  width: auto;
  width:  -webkit-fill-available;
  margin: 10px 10px 0 10px;
}

/* 网格人员组件 */
.grid-people-top{
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 10px 20px;
}
.grid-people-top img{
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
}
.grid-people-botttom{
  background-color: #ffffff;
}
.grid-people-botttom img{
  width: 50px;
  height: 50px;
}
.grid-people-botttom div{
  color: #999999;
}

/* 通知组件 */
.note-info-top{
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 10px 20px;
}
.note-info-top img{
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
}
.note-info-bottom{
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  margin: 10px 20px;
}
.note-background-color{
  background-color: #ffffff;
  padding: 10px 0;
}
.note-info-bottom .note-info-bottom-order{
  background-color: #0099ff;
  color: white;
  border-radius: 0.05rem;
  line-height: 0.37rem;
  text-align: center;
  width: 12px;
  height: 12px;
  display: table;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.note-info-bottom .note-info-bottom-text{
  margin: 0 10px ;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  flex: 1;
  color: #666666;
}
.note-info-bottom .note-info-bottom-text .note-info-bottom-text1{
  margin: 0 0 10px 0;
}
.note-info-bottom .note-info-bottom-text234{
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  width: auto;
  width: -webkit-fill-available;
}
.note-info-bottom .note-info-bottom-text234 .note-info-bottom-text2{
  flex: 1;
  text-align: left;
  color:#0098ff
}
.note-info-bottom .note-info-bottom-text234 .note-info-bottom-text3{
  flex: 1;
  text-align: right;
  color: #999999;
}
.note-info-bottom .note-info-bottom-text234 .van-icon-clock-o{
  margin: 0 4px 0 10px;
  
}
.note-info-bottom .note-info-bottom-text234 .note-info-bottom-text4{
  color: #999999;
}
.note-info-more{
  color: #089BFF;
  font-weight: bold;
  text-align: right;
  margin: 0 30px;
}

/* 意见反馈和系统简介按钮 */
.suggestion-system-info-button{
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
}
.suggestion-info-button{
  width: auto;
  width:  -webkit-fill-available;
  margin: 10px 10px 5px 10px;
}
.suggestion-system-info-button .system-info-button{
  width: auto;
  width:  -webkit-fill-available;
  margin: 10px 10px 15px 10px;
}
.van-button--info {
  border-radius: 10px;
  background-color: #0098ff;
}
.grid-people-botttom img[data-v-fae5bece] {
  width: 1.33333rem;
  height: 1.10333rem;
}

.grid-people-top img[data-v-fae5bece] {
  margin: 0 0.26667rem 0 0;
  width: 0.53333rem;
  height: 0.43333rem;
}

.van-icon::before {
  display: inline-block;
  line-height: 0.48333rem;
}
</style>