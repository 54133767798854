<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default{
    data(){
      return {

      }
    }
  }
</script>

<style lang="less">


</style>
